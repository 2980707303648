import React from 'react';
import SEO from '../common/SEO';
import HeaderOne from '../common/header/HeaderOne';
import SplashFooter from '../common/footer/SplashFooter';


const ComingSoon = () => {
    return (
        <>
        <SEO title="Coming Soon" />
            <main className="main-wrapper">
            <HeaderOne />
                <div className="section section-padding">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="content">
                            <h3>Noël 2024 & Nouvel An 2025</h3>
                                <p style={{ textAlign: 'justify' }}>
                                    Les fêtes approchent et Léonie Dessert vous invite à sublimer vos moments de partage avec des créations pâtissières uniques.<br/>
                                    Laissez-vous emporter par des saveurs envoûtantes qui célèbrent la douceur, l’audace et la gourmandise, spécialement pensées pour Noël et le Nouvel An.
                                </p>
                                <p style={{ textAlign: 'justify' }}>
                                    - <b>Bûche Crème au Beurre</b> (à partir de 4 parts) : 3,80€/part<br/>
                                    <i>(biscuit génoise , crème au beurre : café, ou chocolat, ou praliné)</i><br/><br/>
                                    - <b>Bûche Alliance chocolat/Praliné</b> (à partir de 4 parts) : 4,40€/part<br/>
                                    <i>(dacquoise amande-noisette, croustillant praliné, crémeux 2 chocolats, mousse pralinée)</i><br/><br/>
                                    - <b>Bûche Chocolat blanc/fruits rouges</b> (à partir de 4 parts) : 4,40€/part<br/>
                                    <i>(biscuit génoise nature, crémeux 4 fruits rouges, mousse chocolat blanc, framboises)</i><br/><br/>
                                    - <b>Bûche Saveur Exotique</b> (à partir de 4 parts) : 4,40€/part<br/>
                                    <i>(biscuit noisette/coco, croustillant chocolat-blanc/coco, crémeux exotique, bavaroise rhum/vanille)</i><br/>
                                </p>
                                <p>
                                    Merci de passer votre commande :<br/>
                                    - Avant le <b>19/12</b> (au soir) pour Noël<br/>
                                    - Avant le <b>27/12</b> (au soir) pour le Nouvel An<br/>
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="thumbnail">
                                <img src={process.env.PUBLIC_URL + "/images/christmas-event-2.jpg"} alt="Bûche Chocolat blanc/fruits rouges" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-2">
                            <img src={process.env.PUBLIC_URL + "/images/others/bubble-28.png"} alt="Bubble" />
                        </li>
                        <li className="shape shape-3">
                            <img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Line" />
                        </li>
                    </ul>
                </div>
                <SplashFooter />
            </main>
        </>
    )
}

export default ComingSoon;